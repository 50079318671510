import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-480f5d18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "foodcard" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "msgCard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_col, { cols: "10" }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.food.name), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_b_col, { cols: "2" }, {
          default: _withCtx(() => [
            _createElementVNode("a", {
              href: `/foodedit?foodId=${_ctx.food.id}`
            }, "Edit", 8, _hoisted_2)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_b_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.food.description ?? "Content Content Content Content Content Content Content Content Content Content"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, { cols: "1" }, {
              default: _withCtx(() => [
                _createTextVNode("Qty.")
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, { cols: "3" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_input, {
                  style: {"width":"48px"},
                  type: "number",
                  modelValue: _ctx.qty,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.qty) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, { cols: "4" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_button, {
                  variant: "warning mx-1",
                  onClick: _ctx.decrementQty
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("-")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_b_button, {
                  variant: "warning mx-1",
                  onClick: _ctx.incrementQty
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("+")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, { cols: "3" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_button, {
                  variant: _ctx.buttonVariant,
                  class: "button-color-transition",
                  onClick: _ctx.orderFood
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                  ]),
                  _: 1
                }, 8, ["variant", "onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createElementVNode("span", null, "store:" + _toDisplayString(_ctx.food.store ?? "store"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createElementVNode("strong", null, [
              _createTextVNode("Total Price: "),
              _createElementVNode("span", null, _toDisplayString(_ctx.totalPrice), 1),
              _createElementVNode("span", null, " (" + _toDisplayString(_ctx.food.price) + ")", 1)
            ])
          ]),
          _: 1
        }),
        _createElementVNode("span", null, [
          _createElementVNode("strong", _hoisted_3, _toDisplayString(_ctx.msgCard), 1)
        ])
      ]),
      _: 1
    })
  ]))
}