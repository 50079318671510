import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97c3ffee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_row, null, {
      default: _withCtx(() => [
        (_ctx.showAlert)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`mb-3 alert-bar alert-${_ctx.message.type}`)
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.message.type), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.message.msg), 1)
              ]),
              _createElementVNode("button", {
                class: "close-button",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)))
              }, "X")
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}