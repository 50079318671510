import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AlertBar = _resolveComponent("AlertBar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", null, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createTextVNode("Home")
        ]),
        _: 1
      }),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, { to: "/login" }, {
        default: _withCtx(() => [
          _createTextVNode("Login")
        ]),
        _: 1
      }),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, { to: "/about" }, {
        default: _withCtx(() => [
          _createTextVNode("About")
        ]),
        _: 1
      }),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, { to: "/food" }, {
        default: _withCtx(() => [
          _createTextVNode("Food")
        ]),
        _: 1
      }),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, { to: "/orders" }, {
        default: _withCtx(() => [
          _createTextVNode("Orders")
        ]),
        _: 1
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.msgList, (msg) => {
      return (_openBlock(), _createElementBlock("div", { key: msg }, [
        _createVNode(_component_AlertBar, { message: msg }, null, 8, ["message"])
      ]))
    }), 128)),
    _createVNode(_component_router_view)
  ], 64))
}